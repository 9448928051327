<template lang="pug">
  div
    v-row
      v-col(v-bind="column.id")
        v-text-field(
          v-if="!id"
          label="ID"
          :value="id"
          disabled
          :error-messages="errors.id"
        )
      v-col(v-bind="column.stockCategory")
        v-autocomplete(
          label="Stock Category"
          v-model="stockCategoryId"
          :items="stockCategories"
          item-value="id"
          item-text="name"
          :error-messages="errors.stockCategoryId"
        )
      v-col(v-bind="column.description")
        v-text-field(
          label="Description"
          v-model="description"
          :error-messages="errors.description"
        )
      v-col(v-bind="column.order")
        v-text-field(
          label="Order"
          v-model="order"
          type="number"
          :error-messages="errors.order"
        )
</template>
<script>

const inputVars = () => ({
  id: null,
  stockCategoryId: null,
  description: null,
  order: null,
  // dayStart: null,
  // dayEnd: null,
  // bodyWeight: null,
  // expectedWeight: null,
})

const column = {
  id: { cols: 12 },
  stockCategory: { cols: 12 },
  description: { cols: 12 },
  order: { cols: 12 },
  // dayRange: { cols: 12 },
  // bodyWeight: { cols: 12 },
  // expectedWeight: { cols: 12 },
}

export default {
  name: 'FeedingProgramCategory',
  props: {
    columnAttributes: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    inputFields: {
      type: Object,
      default: inputVars,
    },
    defaultFieldValues: {
      type: Object,
      default: inputVars,
    },
    stockCategories: {
      type: Object,
      default: () => [],
    },
  },
  data: () => ({
    ...inputVars(),
    column,
  }),
  created () {
    this.changeColumnAttributes()
  },
  watch: {
    inputFields: {
      handler (item) {
        this.populateInputFields(item)
      },
      deep: true,
    },
    defaultFieldValues (item) {
      this.populateInputFields(item)
    },
    id () {
      this.updateInputFields()
    },
    stockCategoryId () {
      this.updateInputFields()
    },
    description () {
      this.updateInputFields()
    },
    order () {
      this.updateInputFields()
    },
    // dayStart () {
    //   this.updateInputFields()
    // },
    // dayEnd () {
    //   this.updateInputFields()
    // },
    // bodyWeight () {
    //   this.updateInputFields()
    // },
    // expectedWeight () {
    //   this.updateInputFields()
    // },
  },
  methods: {
    updateInputFields () {
      const fieldValues = this.getFieldValues()
      this.$emit('update:inputFields', fieldValues)
    },
    getFieldValues () {
      return Object.keys(this.inputFields).reduce((result, key) => {
        result[key] = this[key]
        return result
      }, {})
    },
    changeColumnAttributes () {
      const attributes = this.columnAttributes
      this.column = Object.keys(this.column).reduce((result, key) => {
        if (attributes[key]) {
          result[key] = Object.assign({ ...this.column[key], ...attributes[key] })
          return result
        }
        result[key] = Object.assign({ ...this.column[key] })
        return result
      }, {})
    },
    populateInputFields (item) {
      Object.keys(item).forEach(key => {
        const key2 = (key || '').snakeToCamelCase()
        if (item[key] !== this[key2]) this[key2] = item[key]
      })
    },
  },
}
</script>